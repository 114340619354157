






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia, MediaTypes, MediaVideoExtensions, MediaAudioExtensions} from '@/services/api/media.service';

@Component({})

export default class LtiMediaStream extends Vue {
  public MediaTypes = MediaTypes;
  public MediaVideoExtensions = MediaVideoExtensions;
  public MediaAudioExtensions = MediaAudioExtensions;
  
  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;
}
