







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia} from '@/services/api/media.service';
import Iframe from '@/components/UI/Iframe.vue';

@Component({
  components: {
    Iframe
  }
})

export default class LtiMediaIframe extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;

  private get url() {
    return this.data.dataInfo
      ? this.data.dataInfo.versions.directory
        ? this.data.dataInfo.versions.directory.url
        : this.data.dataInfo.versions.original.url
      : this.data.url;
  }
}
