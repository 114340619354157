













import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia} from '@/services/api/media.service';

import ZoomIcon from '@/assets/icon/zoomin.svg';

@Component({
  components: {
    ZoomIcon
  }
})

export default class MediaDocument extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;
  
  public view() {
    this.$store.dispatch(
      'openLightbox',
      {
        url: this.$store.getters.isMediaDocumentViewAvailable(this.data) 
          ? this.data.dataInfo.versions.original.url
          : this.data.dataInfo.versions.preview.url,
        component: this.$store.getters.isMediaDocumentViewAvailable(this.data) 
          ? 'pdf' 
          : 'image'
      }
    );
  }
}
