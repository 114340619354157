









import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import {IMedia, MediaTypes, MediaGroups, MediaTypesGroups, MediaType} from '@/services/api/media.service';
import { IDictionary } from '@/interfaces/interfaces';

import MediaStream from '@/pages/Lti/LtiMedia.stream.vue';
import MediaDocument from '@/pages/Lti/LtiMedia.document.vue';
import MediaIframe from '@/pages/Lti/LtiMedia.iframe.vue';
import MediaError from '@/pages/Lti/LtiMedia.error.vue';

const { mapFields } = createHelpers({
  getterType: 'getMediaField',
  mutationType: 'updateMediaField',
});

@Component({
  components: {
    MediaStream,
    MediaDocument,
    MediaError
  },
  computed: {
    ...mapFields([
      'mediaData'
    ])
  }
})
export default class Details extends Vue {
  private mediaData!: IMedia | null;
  private mediaComponent: null | VueConstructor<Vue> = null;
  private url: string = '';

  private componentDictionary: IDictionary<VueConstructor<Vue>> = {
    DOCUMENT: MediaDocument,
    STREAM: MediaStream,
    IFRAME: MediaIframe
  }

  private mounted() {
    this.setData();
  }

  private setData() {
    const id = +this.$route.params.id;
    this.$store.dispatch('getMediaData', {id})
      .then(() => {
        if (!this.mediaData) return;
        const mediaType = MediaTypes[this.mediaData.mediaType as MediaType];
        const mediaGroup = MediaTypesGroups[this.mediaData.mediaType as MediaType];

        if (!this.$store.getters.canMediaView(this.mediaData)) return;

        if (mediaType === MediaTypes.WEBSEITE) {
          window.location.href = this.mediaData.url;
        }

        if (mediaGroup === MediaGroups.COLLECTION) {
          this.mediaComponent = this.componentDictionary.IFRAME;
        }

        if (mediaGroup === MediaGroups.DOCUMENT) {
          this.mediaComponent = this.componentDictionary.DOCUMENT;
        }

        if (mediaGroup === MediaGroups.STREAM) {
          this.mediaComponent = this.componentDictionary.STREAM;
        }
    });
  }
}
